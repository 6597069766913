import React, {FC, useState, useEffect} from 'react';
import classNames from "classnames";

import {rootStore} from "../../Application";

import {Button} from "../../components/Button";
import {DateTime} from "luxon";
import {tf,processRequestError} from "../../utils/utilities";
import {observer} from 'mobx-react-lite';


interface IHistoryOrdersProps {
    symbol: string;
    baseSymbol: string;
    coinSymbol: string;
}



const HistoryOrders: FC<IHistoryOrdersProps> = ({symbol, baseSymbol, coinSymbol}) => {

    const [activeTab, setactiveTab] = useState<string>('orders')
    const [limit, setLimit] = useState<number>(50)
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false)

    const types = {
        "LIMIT":"лимит",
        "MARKET":"рыночный"
    }
    // @ts-ignore
    useEffect(async () => {
        await loadingOrderCurrent();
    }, []);

    // @ts-ignore
    useEffect(async () => {
            await loadingOrderHistory()
            await loadingOrderCurrent()
    }, [symbol]);

    // @ts-ignore
    useEffect(async () => {
        if (activeTab == "history") {
            await loadingOrderHistory()
        } else {
            await loadingOrderCurrent()
        }

    }, [activeTab]);

    const loadingOrderCurrent = async () => {
        await rootStore.ordersStore.getOrdersCurrent(symbol, limit, page)
        //setItems(rootStore.ordersStore.orderCurrent)
    }
    const loadingOrderHistory = async () => {
        await rootStore.ordersStore.getOrdersHistory(symbol, limit, page)
        //setItems(rootStore.ordersStore.orderHistory)
    }
    const onCancelOrder = async (pk) => {
        setLoading(true)
        try {
            await rootStore.ordersStore.cancelOrder(pk)
            await rootStore.coinStore.getBalancesBuyOne(rootStore.coinStore.balanceBuySymbol)
            await rootStore.coinStore.getBalancesOne(rootStore.coinStore.balanceSellSymbol)

            await loadingOrderCurrent()
        } catch (e) {
            processRequestError(e);
        } finally {
            setLoading(false)
        }
    }



    return (
        <div className="container">
            <div className="tabs js-tabs">
                <ul className="tabs__list js-tab-list">
                    <li className={classNames('tabs__item', {active: activeTab === 'orders'})}
                        onClick={() => setactiveTab('orders')}>Открытые ордера
                    </li>
                    <li className={classNames('tabs__item', {active: activeTab === 'history'})}
                        onClick={() => setactiveTab('history')}>История
                    </li>
                </ul>
                <div className="tabs__content js-tab-content active">
                    <h2 className="operation-title"> {symbol}</h2>
                    <div className="table exchange scroll-wrap">
                        <div className="table__wrap">
                            <div className="table__head">
                                <div className="table__field"><span>Тип</span></div>
                                <div className="table__field table__field_row">
                                    <span>Цена</span>
                                    {/*<div className="table__nav">
                                                    <button className="table__button table__button_top" type="button"/>
                                                    <button className="table__button table__button_down" type="button"/>
                                                </div>*/}
                                </div>
                                <div className="table__field"><span>Объем ({coinSymbol})</span></div>
                                <div className="table__field"><span>Объем ({baseSymbol})</span></div>

                                <div className="table__field"><span>Дата и время</span></div>

                                {activeTab === 'orders' && (
                                    <div className="table__field table__field--mini"><span></span></div>)}
                            </div>
                            {activeTab=="orders" && <div className="table__body scroll-wrap-inner">
                                {rootStore.ordersStore.orderCurrent?.map((one) => (
                                    <div className="table__row" key={one.orderId}>
                                        <div className="table__field"><span>{types[one.type]}</span></div>
                                        {one.type === 'MARKET' && <div className={classNames('table__field', {
                                            success: one.direction === 'BUY',
                                            warning: one.direction === 'SELL',
                                        })}><span>-</span></div>}
                                        {one.type === 'LIMIT' && <div className={classNames('table__field', {
                                            success: one.direction === 'BUY',
                                            warning: one.direction === 'SELL',
                                        })}><span>{one.price}</span></div>}
                                        <div className={classNames('table__field', {
                                            success: one.direction === 'BUY',
                                            warning: one.direction === 'SELL',
                                        })}><span>{one.amount}</span></div>
                                        <div  className={classNames('table__field', {
                                            success: one.direction === 'BUY',
                                            warning: one.direction === 'SELL',
                                        })}><span>{one.turnover}</span></div>
                                        <div className="table__field">
                                            <span>{DateTime.fromISO(one.time).toFormat('D t')}</span></div>
                                        {activeTab === 'orders' && (
                                            <div className="table__field table__field--mini"><span>
                                                <Button kind='tertiary' small={true} type="button" loading={loading}
                                                        onClick={async () => {
                                                            await onCancelOrder(one.orderId.toString())
                                                        }}>отменить</Button>
                                            </span></div>)}
                                    </div>
                                ))}
                            </div>}

                            {activeTab=="history" &&
                            <div className="table__body scroll-wrap-inner">
                                {rootStore.ordersStore.orderHistory?.filter(_i=>(_i.tradedTurnover>0 && _i.tradedAmount>0)).map((one) => (
                                    <div className="table__row" key={one.orderId}>
                                        <div className="table__field"><span>{types[one.type]}</span></div>

                                        {one.type === 'MARKET' && <div className={classNames('table__field', {
                                            success: one.direction === 'BUY',
                                            warning: one.direction === 'SELL',
                                        })}><span>{tf(one.price)}</span></div>}
                                        {one.type === 'LIMIT' && <div className={classNames('table__field', {
                                            success: one.direction === 'BUY',
                                            warning: one.direction === 'SELL',
                                        })}><span>{one.price}</span></div>}

                                        <div className={classNames('table__field', {
                                            success: one.direction === 'BUY',
                                            warning: one.direction === 'SELL',
                                        })}><span>{one.tradedAmount}</span></div>
                                        <div  className={classNames('table__field', {
                                            success: one.direction === 'BUY',
                                            warning: one.direction === 'SELL',
                                        })}><span>{one.tradedTurnover}</span></div>
                                        <div className="table__field">
                                            <span>{DateTime.fromISO(one.time).toFormat('D t')}</span></div>
                                        {activeTab === "orders" && (
                                            <div className="table__field table__field--mini"><span>
                                                <Button kind='tertiary' small={true} type="button" loading={loading}
                                                        onClick={async () => {
                                                            await onCancelOrder(one.orderId.toString())
                                                        }}>отменить</Button>
                                            </span></div>)}
                                    </div>
                                ))}
                            </div>}

                        </div>
                    </div>
                </div>
                <div className="tabs__content js-tab-content"/>

            </div>
        </div>
    )
}


export default observer(HistoryOrders)
