import React from 'react';
import { OtcDeal } from "../../utils/graphql";
import classNames from "classnames";
import { DateTime } from "luxon";
import { Link } from 'react-router-dom';
import { resolve } from "inversify-react";
import { Api } from "../../utils/api";
import {Button} from "../../components/Button";

interface IOTCDealsTabProps {
}

interface IOTCDealsTabState {
    deals: OtcDeal[];
    dateFrom:null | String;
    dateTo:null |String;
    price:null | Decimal;
    status: null | boolean;
}

export class OTCDealsTab extends React.Component<IOTCDealsTabProps, IOTCDealsTabState> {
    private updateInterval: any;

    @resolve(Api)
    declare protected readonly api: Api;

    state: IOTCDealsTabState = {
        deals: [],
        status: null,
        dateFrom: '',
        dateTo: '',
        price: null,
    }

    componentDidMount() {
        this.loadDeals();
        this.updateInterval = setInterval(this.loadDeals, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.updateInterval);
    }

    loadDeals = async () => {
        if (String(this.state.price).length == 0 ) {
            this.setState({price:null})
            this.setState({ deals: await this.api.getMyOtcDeals(this.state.dateFrom,this.state.dateTo,null) });
        } else
        this.setState({ deals: await this.api.getMyOtcDeals(this.state.dateFrom,this.state.dateTo,this.state.price) });
    }

    setStateDeal = async (status) => {
        await this.setState({ price: null })
        await this.setState({ dateTo: null })
        await this.setState({ dateFrom: null })
        await  this.setState({ status: status })
        await this.loadDeals()
    }

    updateDeals  = async () => {
        await this.loadDeals()
        console.log(this.state.dateFrom,this.state.dateTo,this.state.price)
    }


    render() {
        const { deals, status, dateFrom, dateTo, price } = this.state;

        return (
            <div className="tabs__content active">
                <div className="deal">
                    <ul className="deal__list">
                        <li className={classNames('deal__item', { active: status === null })} onClick={() => this.setStateDeal( null )}>Активные</li>
                        <li className={classNames('deal__item', { active: status === true })} onClick={() => this.setStateDeal( true )}>Завершенные</li>
                        <li className={classNames('deal__item', { active: status === false })} onClick={() => this.setStateDeal(false )}>Отмененные</li>
                    </ul>
                    <div className="deal__content  filter-deals">
                        <label>Поиск</label>
                        <div className="filter-deals__block">
                            <label>Интервал дат
                            </label>
                            <input
                                className="main-form__input"
                                type='date'
                                value={this.state.dateFrom|| ''}
                                onChange={e => this.setState({ dateFrom: e.target.value })}
                            />
                            <input
                                className="main-form__input"
                                type='date'
                                value={this.state.dateTo|| ''}
                                onChange={e => this.setState({ dateTo: e.target.value })}
                            />
                        </div>

                        <div className="filter-deals__block">
                            <label>Сумма
                            </label>
                            <input
                                className="main-form__input"
                                type='number'
                                value={this.state.price|| ''}
                                min={0}
                                step={.01}
                                onChange={e => this.setState({ price: e.target.value })}
                            />

                        </div>
                        <Button kind='primary' type="button" onClick={() => this.updateDeals()}>Применить</Button>



                    </div>
                    <div className="deal__content active">
                        <div className="table deal-active scroll-wrap">
                            <div className="table__wrap">
                                <div className="table__head">
                                    <div className="table__field table__field_row">
                                        <span>ID</span>
                                        {/*<div className="table__nav">
                                            <button className="table__button table__button_top" type="button"/>
                                            <button className="table__button table__button_down" type="button"/>
                                        </div>*/}
                                    </div>
                                    <div className="table__field"><span>Дата и время</span></div>
                                    <div className="table__field"><span>Продавец</span></div>
                                    <div className="table__field"><span>Покупатель</span></div>
                                    <div className="table__field"><span>Статус</span></div>
                                    <div className="table__field"><span>Сумма</span></div>
                                    <div className="table__field"><span>Метод оплаты</span></div>
                                    <div className="table__field"><span>Цена</span></div>
                                    <div className="table__field"><span>Комиссия</span></div>
                                    <div className="table__field"><span>На руки</span></div>
                                </div>
                                <div className="table__body scroll-wrap">
                                    {deals.filter(d => d.status === status).map(d => (
                                        <Link to={`/otc/deals/${d.id}`} className="table__row" key={d.id}>
                                            <div className="table__field"><span>{d.id}</span> {d.hasUnread && <span className='unread-marker'>*</span>}</div>
                                            <div className="table__field"><span>{DateTime.fromISO(d.datetime).toLocaleString(DateTime.DATETIME_SHORT)}</span></div>
                                            <div className="table__field"><span>{d.ad.action === 'SELL' ? d.ad.user.name : d.user.name}</span></div>
                                            <div className="table__field"><span>{d.ad.action === 'BUY' ? d.ad.user.name : d.user.name}</span></div>
                                            <div className={classNames('table__field', { success: d.status === null || d.status === true, warning: d.status === false })}>
                                                <span>{d.status === null ? 'Активный' : d.status === true ? 'Завершенный' : 'Отменен'}</span>
                                            </div>
                                            <div className="table__field table__field_col">
                                                <span className="warning">{d.amount} {d.ad.currency}</span>
                                                {/*<span className="info-text">(516.51 CFR)</span>*/}
                                            </div>
                                            <div className="table__field table__field_col">
                                                <span>{d.ad.paymentMethod}</span>
                                            </div>
                                            <div className="table__field table__field_col">
                                                <span className="warning">{d.amount * (1 + parseFloat(d.ad.priceExtraPercent) / 100)}</span>
                                                {/*<span className="info-text">(516.51 CFR)</span>*/}
                                            </div>
                                            <div className="table__field"><span>+{d.ad.priceExtraPercent}% комиссия</span></div>
                                            <div className="table__field table__field_col">
                                                <span className="warning">{d.amount} {d.ad.currency}</span>
                                                {/*<span className="info-text">(516.51 CFR)</span>*/}
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
