import React from 'react';
import classNames from "classnames";
import { pd, processRequestError } from "../utils/utilities";
import { resolve } from "inversify-react";
import { AuthStore } from "../stores";
import { Button } from "../components/Button";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import QRCode from 'react-qr-code';
import PhoneInput from 'react-phone-input-2';
import { RouterStore } from "mobx-react-router";
import { reaction, when } from 'mobx';

interface IProfilePageProps {
}

interface IProfilePageState {
    activeTab: null | 'name' | 'phone' | 'password' | '2fa' | 'tg';
    newName: string;
    oldPassword: string;
    password: string;
    password2: string;
    newPhone: string;
    otpCode: string;
    tgUsername: string;
    inviterTgUsername: string;
    loading: boolean;
}

@observer
export class ProfilePage extends React.Component<IProfilePageProps, IProfilePageState> {
    private reactionDisposer: any;

    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    state: IProfilePageState = {
        activeTab: null,
        newName: '',
        oldPassword: '',
        password: '',
        password2: '',
        newPhone: '',
        otpCode: '',
        tgUsername: '',
        inviterTgUsername: '',
        loading: false,
    }

    componentDidMount() {
        this.reactionDisposer = when(
            () => !!(this.authStore.profile?.tgUsername || this.authStore.profile?.inviterTgUsername),
            () => this.setState({
                tgUsername: this.authStore.profile?.tgUsername || '',
                inviterTgUsername: this.authStore.profile?.inviterTgUsername || '',
            })
        );
    }

    componentWillUnmount() {
        this.reactionDisposer();
    }

    onUpdateName = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.updateName(this.state.newName);
            toast.success('Изменения сохранены');
            this.setState({ newName: '', activeTab: null });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    onUpdatePassword = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.updatePassword(this.state.oldPassword, this.state.password);
            toast.success('Изменения сохранены');
            this.setState({ oldPassword: '', password: '', password2: '', activeTab: null });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    onUpdatePhone = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.updatePhone('+' + this.state.newPhone);
            toast.success('Изменения сохранены');
            this.setState({ newPhone: '', activeTab: null });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    onToggle2FA = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.toggle2fa(this.state.otpCode);
            toast.success('Изменения сохранены');
            this.setState({ otpCode: '', activeTab: null });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    onUpdateTg = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.updateTg(this.state.tgUsername, this.state.inviterTgUsername);
            toast.success('Изменения сохранены');
            this.setState({ activeTab: null });
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { activeTab, newName, oldPassword, password, password2, newPhone, otpCode, tgUsername, inviterTgUsername, loading } = this.state;

        return (
            <main className="main">
                <section className="otc-section">
                    <div className="container">
                        <div className="profile-wrap">
                            <div className="chat js-chat-tabs">
                                <div className="chat__list-wrap">
                                    <h2 className="operation-title active">Основная информация</h2>
                                    <ul className="chat__list">
                                        <li className={classNames(['chat__item', { active: activeTab === 'name' }])}>
                                            <div className="chat__profile-info">
                                                <span className="chat__profile-label">Никнейм</span>
                                                <span className="chat__base-text">Ваш никнейм: <span className="chat__profile-label">{this.authStore.profile?.name} </span></span>
                                                {this.authStore.profile?.referrer && <span className="chat__base-text">Пригласитель: <span className="chat__profile-label">{this.authStore.profile?.referrer}</span></span>}
                                            </div>
                                            <div className="chat__btn-wrap">
                                                <button
                                                    className={classNames('btn fourthly small', { active: activeTab !== 'name' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: 'name' })}
                                                >
                                                    Изменить
                                                </button>
                                                <button
                                                    className={classNames('signin', { active: activeTab === 'name' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: null })}
                                                >
                                                    Отмена
                                                </button>
                                            </div>
                                        </li>
                                        <li className={classNames(['chat__item', { active: activeTab === 'phone' }])}>
                                            <div className="chat__profile-info">
                                                <span className="chat__profile-label">Телефон</span>
                                                <span className="chat__base-text">Вы можете изменить номер телефона</span>
                                            </div>
                                            <div className="chat__btn-wrap">
                                                <button
                                                    className={classNames('btn fourthly small', { active: activeTab !== 'phone' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: 'phone' })}
                                                >
                                                    Изменить
                                                </button>
                                                <button
                                                    className={classNames('signin', { active: activeTab === 'phone' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: null })}
                                                >
                                                    Отмена
                                                </button>
                                            </div>
                                        </li>
                                        <li className={classNames(['chat__item', { active: activeTab === 'password' }])}>
                                            <div className="chat__profile-info">
                                                <span className="chat__profile-label">Пароль</span>
                                                <span className="chat__base-text">Этот пароль необходим для входа в систему, пожалуйста, запомните его.</span>
                                            </div>
                                            <div className="chat__btn-wrap">
                                                <button
                                                    className={classNames('btn fourthly small', { active: activeTab !== 'password' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: 'password' })}
                                                >
                                                    Изменить
                                                </button>
                                                <button
                                                    className={classNames('signin', { active: activeTab === 'password' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: null })}
                                                >
                                                    Отмена
                                                </button>
                                            </div>
                                        </li>
                                        <li className={classNames(['chat__item', { active: activeTab === '2fa' }])}>
                                            <div className="chat__profile-info">
                                                <span className="chat__profile-label">2FA</span>
                                                <span className="chat__base-text">Вы можете изменить 2FA</span>
                                            </div>
                                            <div className="chat__btn-wrap">
                                                <button
                                                    className={classNames('btn fourthly small', { active: activeTab !== '2fa' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: '2fa' })}
                                                >
                                                    Изменить
                                                </button>
                                                <button
                                                    className={classNames('signin', { active: activeTab === '2fa' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: null })}
                                                >
                                                    Отмена
                                                </button>
                                            </div>
                                        </li>
                                        <li className={classNames(['chat__item', { active: activeTab === 'tg' }])}>
                                            <div className="chat__profile-info">
                                                <span className="chat__profile-label">Контактная информация</span>
                                                <span className="chat__base-text">Вы можете изменить контактную информацию</span>
                                            </div>
                                            <div className="chat__btn-wrap">
                                                <button
                                                    className={classNames('btn fourthly small', { active: activeTab !== 'tg' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: 'tg' })}
                                                >
                                                    Изменить
                                                </button>
                                                <button
                                                    className={classNames('signin', { active: activeTab === 'tg' })}
                                                    type="button"
                                                    onClick={() => this.setState({ activeTab: null })}
                                                >
                                                    Отмена
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="profile-footer">
                                        {this.authStore.profile?.emailValidated && (
                                            <div className="chat__verified-wrap">
                                                <span className="chat__verified-title">Ваш аккаунт подтвержден</span>
                                                <p className="chat__base-text">Благодаря этому мы защитим вашу учетную
                                                    запись, используя как пароль так и номер телефона, а также электронную
                                                    почту.</p>
                                            </div>
                                        )}
                                        <button
                                            className="btn tertiary small"
                                            type="button"
                                            onClick={() => this.authStore.logout()}
                                        >
                                            Выйти из профиля
                                        </button>
                                    </div>
                                </div>
                                <div className={classNames(['chat__content', { active: activeTab === 'name' }])}>
                                    <h2 className="operation-title">Никнейм</h2>
                                    <form className="main-form" onSubmit={this.onUpdateName}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Текущий</label>
                                                <input
                                                    className="main-form__input main-form__input_dis"
                                                    type="text"
                                                    disabled
                                                    value={this.authStore.profile?.name}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Новый</label>
                                                <input
                                                    className="main-form__input"
                                                    type="text"
                                                    value={newName}
                                                    onChange={e => this.setState({ newName: e.target.value })}
                                                />
                                            </div>
                                            <Button kind='primary' type='submit' disabled={!newName} loading={loading}>Сохранить</Button>
                                        </div>
                                    </form>
                                </div>
                                <div className={classNames(['chat__content', { active: activeTab === 'phone' }])}>
                                    <h2 className="operation-title">Телефон</h2>
                                    <form className="main-form" onSubmit={this.onUpdatePhone}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Текущий</label>
                                                <input
                                                    className="main-form__input main-form__input_dis"
                                                    type="text"
                                                    disabled
                                                    value={this.authStore.profile?.phone}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Новый</label>
                                                <PhoneInput
                                                    country='ru'
                                                    inputProps={{ className: 'main-form__input' }}
                                                    value={newPhone}
                                                    onChange={newPhone => this.setState({ newPhone })}
                                                    disableDropdown
                                                />
                                            </div>
                                            <Button kind='primary' type='submit' disabled={!newPhone} loading={loading}>Сохранить</Button>
                                        </div>
                                    </form>
                                </div>
                                <div className={classNames(['chat__content', { active: activeTab === 'password' }])}>
                                    <h2 className="operation-title">Пароль</h2>
                                    <form className="main-form" onSubmit={this.onUpdatePassword}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Текущий</label>
                                                <input
                                                    className="main-form__input"
                                                    type="password"
                                                    value={oldPassword}
                                                    onChange={e => this.setState({ oldPassword: e.target.value })}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Новый</label>
                                                <input
                                                    className="main-form__input"
                                                    type="password"
                                                    value={password}
                                                    onChange={e => this.setState({ password: e.target.value })}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Новый повтор.</label>
                                                <input
                                                    className="main-form__input"
                                                    type="password"
                                                    value={password2}
                                                    onChange={e => this.setState({ password2: e.target.value })}
                                                />
                                            </div>
                                            <Button kind='primary' type='submit' disabled={!oldPassword || !password || password !== password2} loading={loading}>Сохранить</Button>
                                        </div>
                                    </form>
                                </div>
                                <div className={classNames(['chat__content', { active: activeTab === '2fa' }])}>
                                    <h2 className="operation-title">2FA</h2>
                                    <form className="main-form" onSubmit={this.onToggle2FA}>
                                        <div className="main-form__wrap">
                                            {this.authStore.profile?.otpEnabled ? (
                                                <></>
                                            ) : (
                                                <div className='main-form__qr'>
                                                    <div>Отсканируйте QR-код при помощи Google Authenticator для включения 2FA</div>
                                                    <div className="main-form__qr__code-wrapper">
                                                        <QRCode
                                                            value={`otpauth://totp/${this.authStore.profile?.name}?secret=${this.authStore.profile?.otpSecret}&issuer=TopExchange`}
                                                            bgColor='#f8f8f8'
                                                        />
                                                    </div>
                                                    <div>Или используйте текстовый секрет: <code>{this.authStore.profile?.otpSecret}</code></div>
                                                </div>
                                            )}
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Код 2FA</label>
                                                <input
                                                    className="main-form__input"
                                                    type="text"
                                                    maxLength={6}
                                                    value={otpCode}
                                                    onChange={e => this.setState({ otpCode: e.target.value })}
                                                />
                                            </div>
                                            <Button kind='primary' type='submit' disabled={otpCode.length !== 6} loading={loading}>
                                                {this.authStore.profile?.otpEnabled ? 'Отключить' : 'Включить'}
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                                <div className={classNames(['chat__content', { active: activeTab === 'tg' }])}>
                                    <h2 className="operation-title">Контактная информация (Telegram)</h2>
                                    <form className="main-form" onSubmit={this.onUpdateTg}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">User</label>
                                                <input
                                                    className="main-form__input"
                                                    type="text"
                                                    value={tgUsername}
                                                    onChange={e => this.setState({ tgUsername: e.target.value })}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Referrer</label>
                                                <input
                                                    className="main-form__input"
                                                    type="text"
                                                    value={inviterTgUsername}
                                                    onChange={e => this.setState({ inviterTgUsername: e.target.value })}
                                                />
                                            </div>
                                            <Button kind='primary' type='submit' disabled={!tgUsername} loading={loading}>Сохранить</Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
}