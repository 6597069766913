import React, {FC, useState, useEffect} from 'react';

import {observer} from 'mobx-react-lite';
import {rootStore} from "../../Application";
import {tf, normalizeVolume, PrecisionCount} from "../../utils/utilities";

import {BalanceExchangeType, CoinType} from "../../utils/graphql";
import { toast } from "react-toastify"

interface IFormBuyProps  {
    symbol: string;
    mantissa: number
}

const TabOne: FC<IFormBuyProps > = ({symbol, mantissa=2}) => {

     // @ts-ignore
    useEffect(async () => {
        //await rootStore.coinStore.getBalancesAll()
    
    }, []);


    const onGetBalance = () => {
        if ( rootStore.coinStore.balances) {
            let findOne: BalanceExchangeType = rootStore.coinStore.balances.find((one: BalanceExchangeType) => one.symbol == symbol)
            if (findOne) {
                return findOne.balance
            }
        }
        return 0
    }
    const onGetFreezBalance = () => {
        if ( rootStore.coinStore.balances) {
            let findOne: BalanceExchangeType = rootStore.coinStore.balances.find((one: BalanceExchangeType) => one.symbol == symbol)
            if (findOne) {
                return findOne.freezeBalance
            }
        }
        return 0
    }
    return (
        <div className="tabs__item-info"><span className="tabs__item-name">{symbol}</span>
            <div className="tabs__item-line">
                <span>{tf(onGetFreezBalance(), mantissa)}</span>

            </div>
        </div>
    )
}

export default observer(TabOne)
