import { JsonProperty, Serializable } from "typescript-json-serializer";
import { DateTime } from "luxon";

const DATETIME_PROPERTY = { onDeserialize: (val: string) => DateTime.fromISO(val) }
const DECIMAL_PROPERTY = { onDeserialize: (val: string) => parseFloat(val) }


@Serializable()
export class ChatMessage {
    @JsonProperty() pk: number;
    @JsonProperty() deal_id: number | null;
    @JsonProperty() other_user_id: number;
    @JsonProperty() other_user_name: string;
    @JsonProperty() incoming: boolean;
    @JsonProperty(DATETIME_PROPERTY) datetime: DateTime;
    @JsonProperty() text: string;
    @JsonProperty() service: boolean;
    @JsonProperty() complete_deals?: number;
    @JsonProperty() attachment?: string;
}
