import React from 'react';
import { OperationLogType } from "../utils/graphql";
import { DateTime } from "luxon";
import { tf } from "../utils/utilities";

interface IHistoryWithdrawProps {
    operations?: OperationLogType[];
    mantissa: number;
    withoutDestination?: boolean;
}

interface IHistoryWithdrawState {
}

export class HistoryWithdraw extends React.Component<IHistoryWithdrawProps, IHistoryWithdrawState> {
    render() {
        const { operations, mantissa, withoutDestination } = this.props;

        return (
            <div className="table withdrawal scroll-wrap">
                <div className="table__wrap">
                    <div className="table__head">
                        <div className="table__field table__field_row">
                            <span>Время</span>
                            {/*<div className="table__nav">
                                <button className="table__button table__button_top" type="button"/>
                                <button className="table__button table__button_down" type="button"/>
                            </div>*/}
                        </div>
                        {!withoutDestination && <div className="table__field"><span>Адрес</span></div>}
                        <div className="table__field"><span>Сумма</span></div>
                        <div className="table__field"><span>Статус</span></div>
                    </div>
                    <div className="table__body scroll-wrap">
                        {operations?.map(w => (
                            <div className="table__row" key={w.id}>
                                <div className="table__field"><span>{DateTime.fromISO(w.datetime).toFormat('D t')}</span></div>
                                {!withoutDestination && <div className="table__field"><span>{w.destination}</span></div>}
                                <div className="table__field table__field_col">
                                    <span>{tf(w.value, mantissa)}</span>
                                    {/*<span className="info-text">(+0,5% комиссия)</span>*/}
                                </div>
                                {w.status === true && <div className="table__field success"><span>Подтверждено</span></div>}
                                {w.status === false && <div className="table__field warning"><span>Отмена</span></div>}
                                {w.status === null && <div className="table__field info-text"><span>Ожидание</span></div>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}