import React, {FC, useState, useEffect} from 'react';

import {observer} from 'mobx-react-lite';
import {rootStore} from "../../Application";
import {tf, normalizeVolume, PrecisionCount} from "../../utils/utilities";

import {BalanceExchangeType, CoinType} from "../../utils/graphql";
import { toast } from "react-toastify"

interface IFormBuyProps  {
    symbol: string;
    mantissa?: number;
}

const BalanceLine: FC<IFormBuyProps > = ({symbol,mantissa=2}) => {

     // @ts-ignore
    useEffect(async () => {
    
    }, []);


    const onGetBalance = () => {
        if ( rootStore.coinStore.balances) {
            let findOne: BalanceExchangeType = rootStore.coinStore.balances.find((one: BalanceExchangeType) => one.symbol == symbol)
            if (findOne) {
                return findOne.balance
            }
        }
        return 0
    }
    const onGetFreezBalance = () => {
        if ( rootStore.coinStore.balances) {
            let findOne: BalanceExchangeType = rootStore.coinStore.balances.find((one: BalanceExchangeType) => one.symbol == symbol)
            if (findOne) {
                return findOne.freezeBalance
            }
        }
        return 0
    }

    return (
        <div className="balance-head">
                                    <h2 className="operation-title">Пополнение в {symbol}</h2>
                                    <span className="balance">{tf(onGetBalance(),mantissa)} {symbol}</span>
                                    <span className="balance cold">{tf(onGetFreezBalance(),mantissa)} {symbol}</span>
        </div>
    )
}

export default observer(BalanceLine)
