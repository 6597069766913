import React from 'react';
import { Modal } from "../components/Modal";
import { Modals, ModalStore } from "../stores/ModalStore";
import { resolve } from "inversify-react";
import { pd, processRequestError } from "../utils/utilities";
import { observer } from "mobx-react";
import { AuthStore } from "../stores";
import { toast } from "react-toastify";
import { Button } from "../components/Button";

interface ISignUpModalProps {
}

interface ISignUpModalState {
    name: string;
    email: string;
    password: string;
    password2: string;
    loading: boolean;
}

@observer
export class SignUpModal extends React.Component<ISignUpModalProps, ISignUpModalState> {
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    state: ISignUpModalState = {
        name: '',
        email: '',
        password: '',
        password2: '',
        loading: false,
    }

    onSubmit = async (e: React.FormEvent) => {
        pd(e);
        this.setState({ loading: true });
        try {
            await this.authStore.register(this.state.name, this.state.email, this.state.password);
            toast.success('Регистрация произведена успешно, теперь вы можете войти');
            this.modalStore.showModal(Modals.SignIn);
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { name, email, password, password2, loading } = this.state;

        return (
            <Modal modalKey={Modals.SignUp}>
                <form className="main-form" onSubmit={this.onSubmit}>
                    <div className="main-form__wrap">
                        <h3 className="main-form__title">Регистрация</h3>
                        <div className="main-form__field">
                            <input
                                className="main-form__input"
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={e => this.setState({ name: e.target.value })}
                            />
                        </div>
                        <div className="main-form__field">
                            <input
                                className="main-form__input"
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={e => this.setState({ email: e.target.value })}
                            />
                        </div>
                        <div className="main-form__field">
                            <input
                                className="main-form__input"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={e => this.setState({ password: e.target.value })}
                            />
                        </div>
                        <div className="main-form__field">
                            <input
                                className="main-form__input"
                                type="password"
                                placeholder="Confirm password"
                                value={password2}
                                onChange={e => this.setState({ password2: e.target.value })}
                            />
                        </div>
                        <Button kind='primary' type='submit' loading={loading} disabled={!name || !email || !password || password !== password2}>Регистрация</Button>
                        <div className="main-form__nav">
                            <span className="main-form__text">Уже есть аккаунт?</span>
                            <button className="signin" type="button" onClick={() => this.modalStore.showModal(Modals.SignIn)}>Вход</button>
                        </div>
                    </div>
                </form>
            </Modal>
        )
    }
}