import { makeObservable, observable, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import { IndexPageInfoType } from "../utils/graphql";
import { WS_HOST, WS_PROTO } from "../utils/const";
import BN from "bignumber.js";
import { toBNJS } from "../utils/utilities";
import _ from "lodash";
import store from "store";

export class InfoStore {
    @observable globalWebsocket: WebSocket;
    @observable indexPage?: IndexPageInfoType;
    @observable rates: { [key: string]: BN } = { btcrub: toBNJS(0), usdtrub: toBNJS(0) };

    get api() {
        return this.rootStore.api;
    }

    constructor(protected rootStore: RootStore) {
        makeObservable(this);
        this.loadIndexPage();
        let token = store.get('token');
        console.log(token)
        if (token)  {
            this.globalWebsocket = new WebSocket(`${WS_PROTO}://${WS_HOST}/ws/global/?token=${token}`);
            this.globalWebsocket.addEventListener('message', this.onWebsocketMessage);
        }

    }

    onWebsocketMessage = (e: MessageEvent) => {
        const data = JSON.parse(e.data);
        _.forEach(data, (value, key) => {
            data[key] = toBNJS(value);
        })

        if (data.action === 'tickers') {
            runInAction(() => this.rates = data);
        }
    }

    loadIndexPage = async () => {
        const indexPage = await this.api.indexPage();
        runInAction(() => this.indexPage = indexPage);
    }
}
