import React from 'react';
import { OperationLogType } from "../utils/graphql";
import { DateTime } from "luxon";
import { tf } from "../utils/utilities";

interface IHistoryWithdrawRubProps {
    operations?: OperationLogType[];
}

interface IHistoryWithdrawRubState {
}

export class HistoryWithdrawRub extends React.Component<IHistoryWithdrawRubProps, IHistoryWithdrawRubState> {
    render() {
        const { operations } = this.props;

        return (
            <div className="table withdrawal scroll-wrap">
                <div className="table__wrap">
                    <div className="table__head">
                        <div className="table__field table__field_row">
                            <span>Время</span>
                            {/*<div className="table__nav">
                                <button className="table__button table__button_top" type="button"/>
                                <button className="table__button table__button_down" type="button"/>
                            </div>*/}
                        </div>
                        <div className="table__field"><span>Код</span></div>
                        <div className="table__field"><span>Сумма</span></div>
                        <div className="table__field"><span>Статус</span></div>
                    </div>
                    <div className="table__body scroll-wrap">
                        {operations?.map(w => (
                            <div className="table__row" key={w.id}>
                                <div className="table__field"><span>{DateTime.fromISO(w.datetime).toFormat('D t')}</span></div>
                                <div className="table__field"><span>{w.destination}</span></div>
                                <div className="table__field table__field_col">
                                    <span>{tf(w.value, 2)}</span>
                                    {/*<span className="info-text">(+0,5% комиссия)</span>*/}
                                </div>
                                {w.status === null && <div className='table__field info-text'><span>Не использован</span></div>}
                                {w.status === true && <div className='table__field success'><span>Использован</span></div>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}