import React from 'react';
import { observer } from "mobx-react";
import { resolve } from "inversify-react";
import { AuthStore } from "../stores";
import { Redirect, RouteProps } from "react-router";
import { Route } from "react-router-dom";
import { toast } from "react-toastify";

interface IProtectedRouteProps extends RouteProps {
}

interface IProtectedRouteState {
}

@observer
export class ProtectedRoute extends React.Component<IProtectedRouteProps, IProtectedRouteState> {
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    render() {
        const { component: Component, render, ...rest } = this.props;

        return (
            <Route
                {...rest}
                render={props => {
                    if (!this.authStore.token) {
                        toast.error('Для просмотра этой страницы необходимо войти в аккаунт');
                        return <Redirect to='/' />;
                    }
                    return Component ? <Component {...props} /> : render(props);
                }}
            />
        )
    }
}
