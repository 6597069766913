import React from 'react';
import { OtcAdsType } from "../../utils/graphql";
import { resolve } from "inversify-react";
import { Api } from "../../utils/api";
import { Button } from "../../components/Button";
import { RouterStore } from "mobx-react-router";
import { processRequestError } from "../../utils/utilities";

interface IOTCIndexTabProps {
}

interface IOTCIndexTabState {
    ads?: OtcAdsType;
    loading: boolean;
    loadingId?: string;
}

export class OTCIndexTab extends React.Component<IOTCIndexTabProps, IOTCIndexTabState> {
    @resolve(Api)
    declare protected readonly api: Api;
    @resolve(RouterStore)
    declare protected readonly routerStore: RouterStore;

    state: IOTCIndexTabState = {
        loading: false,
    }

    componentDidMount() {
        this.loadAds();
    }

    loadAds = async () => {
        this.setState({ ads: await this.api.getOtcAds() });
    }

    onStartDeal = async (id: string) => {
        this.setState({ loading: true, loadingId: id });
        try {
            const dealId = await this.api.otcStartDeal(id);
            this.routerStore.push(`/otc/deals/${dealId}`);
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false, loadingId: undefined });
        }
    }

    render() {
        const { ads, loading, loadingId } = this.state;

        return (
            <div className="tabs__content active">
                {/*<div className="operation-head">
                    <form className="main-form" action="#">
                        <div className="main-form__wrap">
                            <div className="main-form__field">
                                <label className="main-form__label" htmlFor="#"/>
                                <input className="main-form__input" type="text" placeholder="Метод оплаты"/>
                            </div>
                            <div className="main-form__field main-form__field_sum">
                                <label className="main-form__label" htmlFor="#"/>
                                <input className="main-form__input" type="text" placeholder="Сумма"/>
                            </div>
                            <button className="btn primary" type="submit">Найти</button>
                        </div>
                    </form>
                </div>*/}
                <div className="main-content column">
                    <div className="main-block">
                        <h2 className="operation-title">Покупка кода - пополнить баланс биржи</h2>
                        <div className="operation operation_head">
                            <div className="operation__wrap">
                                <div className="operation__block">
                                    <span className="operation__title">Продавец</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">Метод оплаты</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">Цена</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">Сумма</span>
                                </div>
                            </div>
                        </div>
                        <div className="operation-wrap row-head scroll-wrap">
                            {ads?.buy.filter(_i=>_i.status==='ACTIVE').map(ad => (
                                <div className="operation" key={ad.id}>
                                    <div className="operation__wrap">
                                        <div className="operation__block">
                                            <span className="operation__title">Продавец</span>
                                            <span className="operation__value">{ad.user.name} ({ad.user.id})</span>
                                            {/*<span className="operation__status">доверенный</span>*/}
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">Метод оплаты</span>
                                            <span className="operation__value">{ad.paymentMethod}</span>
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">Цена</span>
                                            <span className="operation__value">{ad.priceExtraPercent}%</span>
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">Сумма</span>
                                            <span className="operation__value">{ad.minSum} - {ad.maxSum} {ad.currency}</span>
                                            {/*<span className="operation__value operation__value_sub">516.51 - 1032.02 (CFR)</span>*/}
                                        </div>
                                        <div className="operation__btn-wrap">
                                            <Button kind='fourthly' disabled={loading} loading={loadingId === ad.id} onClick={() => this.onStartDeal(ad.id)}>Продать</Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="main-block">
                        <h2 className="operation-title">Продажа кода - вывести баланс биржи</h2>
                        <div className="operation operation_head">
                            <div className="operation__wrap">
                                <div className="operation__block">
                                    <span className="operation__title">Продавец</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">Метод оплаты</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">Цена</span>
                                </div>
                                <div className="operation__block">
                                    <span className="operation__title">Сумма</span>
                                </div>
                            </div>
                        </div>
                        <div className="operation-wrap row-head scroll-wrap">
                            {ads?.sell.filter(_i=>_i.status==='ACTIVE').map(ad => (
                                <div className="operation" key={ad.id}>
                                    <div className="operation__wrap">
                                        <div className="operation__block">
                                            <span className="operation__title">Продавец</span>
                                            <span className="operation__value">{ad.user.name} ({ad.user.id})</span>
                                            {/*<span className="operation__status">доверенный</span>*/}
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">Метод оплаты</span>
                                            <span className="operation__value">{ad.paymentMethod}</span>
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">Цена</span>
                                            <span className="operation__value">{ad.priceExtraPercent}%</span>
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">Сумма</span>
                                            <span className="operation__value">{ad.minSum} - {ad.maxSum} {ad.currency}</span>
                                            {/*<span className="operation__value operation__value_sub">516.51 - 1032.02 (CFR)</span>*/}
                                        </div>
                                        <div className="operation__btn-wrap">
                                            <Button kind='primary' disabled={loading} loading={loadingId === ad.id} onClick={() => this.onStartDeal(ad.id)}>Купить</Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
