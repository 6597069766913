import React from 'react';
import { OperationLogType } from "../utils/graphql";
import { DateTime } from "luxon";
import { tf } from "../utils/utilities";

interface IHistoryDepositProps {
    operations?: OperationLogType[];
    mantissa: number;
}

interface IHistoryDepositState {
}

export class HistoryDeposit extends React.Component<IHistoryDepositProps, IHistoryDepositState> {
    render() {
        const { operations, mantissa } = this.props;

        return (
            <div className="table assets scroll-wrap">
                <div className="table__wrap">
                    <div className="table__head">
                        <div className="table__field table__field_row"><span>Время</span>
                            {/*<div className="table__nav">
                                <button className="table__button table__button_top" type="button"/>
                                <button className="table__button table__button_down" type="button"/>
                            </div>*/}
                        </div>
                        <div className="table__field"><span>Номер</span></div>
                        <div className="table__field"><span>Сумма </span></div>
                        <div className="table__field"><span>Статус</span></div>
                    </div>
                    <div className="table__body scroll-wrap">
                        {operations?.map(d => (
                            <div className="table__row" key={d.id}>
                                <div className="table__field"><span>{DateTime.fromISO(d.datetime).toFormat('D t')}</span></div>
                                <div className="table__field"><span>{d.id}</span></div>
                                <div className="table__field"><span>{tf(d.value, mantissa)}</span></div>
                                {d.status === true && <div className="table__field success"><span>Подтверждено</span></div>}
                                {d.status === false && <div className="table__field warning"><span>Отмена</span></div>}
                                {d.status === null && <div className="table__field info-text"><span>Ожидание</span></div>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}