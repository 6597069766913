import React from 'react';
import { resolve } from "inversify-react";
import { Api } from "../../utils/api";
import { observer } from "mobx-react";
import { Button } from "../../components/Button";
import { pd, processRequestError } from "../../utils/utilities";
import { toast } from "react-toastify";
import { OtcAd } from "../../utils/graphql";
import { ModalStore } from "../../stores";

interface IOTCAdsTabProps {
}

interface IOTCAdsTabState {
    myAds?: OtcAd[];
    action: 'buy' | 'sell';
    currency: string;
    minSum: string;
    maxSum: string;
    paymentMethod: string;
    description: string;
    priceExtraPercent: string;
    loading?: boolean;
    deletingId?: string;
    deleteLoading?: boolean;
    editingId?: string;
}

const initialState: IOTCAdsTabState = {
    action: 'buy',
    currency: 'rub',
    minSum: '0.00',
    maxSum: '0.00',
    paymentMethod: '',
    description: '',
    priceExtraPercent: '0',
}

@observer
export class OTCAdsTab extends React.Component<IOTCAdsTabProps, IOTCAdsTabState> {
    @resolve(Api)
    declare protected readonly api: Api;
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;

    state: IOTCAdsTabState = {
        myAds: [],
        ...initialState,
        loading: false,
        deleteLoading: false,
    }

    componentDidMount() {
        this.loadAds();
    }

    loadAds = async () => {
        this.setState({ myAds: await this.api.getMyOtcAds() });
    }

    onCreate = async (e: React.FormEvent) => {
        pd(e);
        const { action,currency, minSum, maxSum, paymentMethod, description, priceExtraPercent } = this.state;
        this.setState({ loading: true });
        try {
            await this.api.otcCreateAd(action,currency, minSum, maxSum, paymentMethod, description, priceExtraPercent);
            this.setState(initialState);
            toast.success('Объявление было успешно создано');
            await this.loadAds();
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    onEdit = async (e: React.FormEvent) => {
        pd(e);
        const { action,currency, minSum, maxSum, paymentMethod, description, priceExtraPercent, editingId } = this.state;
        this.setState({ loading: true });
        try {
            await this.api.otcEditAd(editingId, action,currency, minSum, maxSum, paymentMethod, description, priceExtraPercent);
            this.setState({ ...initialState, editingId: undefined });
            toast.success('Объявление было успешно изменено');
            await this.loadAds();
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ loading: false });
        }
    }

    onDelete = async (id: string) => {
        this.setState({ deleteLoading: true });
        try {
            await this.api.otcDeleteAd(id)
            toast.success('Объявление было успешно удалено');
            await this.loadAds();
        } catch (e) {
            processRequestError(e);
        } finally {
            this.setState({ deleteLoading: false, deletingId: undefined });
        }
    }

    render() {
        const { myAds, action,currency, minSum, maxSum, paymentMethod, description, priceExtraPercent, loading, deletingId, deleteLoading, editingId } = this.state;

        return (
            <div className="tabs__content active">
                <div className="main-content">
                    <div className="main-block">
                        <h2 className="operation-title">Мои объявления</h2>
                        <div className="operation-wrap scroll-wrap">
                            {myAds.map(ad => (
                                <div className="operation" key={ad.id}>
                                    <div className="operation__wrap">
                                        <div className="operation__block">
                                            <span className="operation__title">{ad.action === 'SELL' ? 'Продавец' : 'Покупатель'}</span>
                                            <span className="operation__value">{ad.user.name} ({ad.user.id})</span>
                                            {/*<span className="operation__status">доверенный</span>*/}
                                        </div>

                                        <div className="operation__block">
                                            <span className="operation__title">Метод оплаты</span>
                                            <span className="operation__value">{ad.paymentMethod}</span>
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">Цена</span>
                                            <span className="operation__value">{ad.priceExtraPercent}%</span>
                                        </div>
                                        <div className="operation__block">
                                            <span className="operation__title">Сумма</span>
                                            <span className="operation__value">{ad.minSum} - {ad.maxSum} {ad.currency}</span>
                                            {/*<span className="operation__value operation__value_sub">516.51 - 1032.02 (CFR)</span>*/}
                                        </div>

                                        <div className="operation__btn-wrap operation__btn-wrap_func">
                                            {editingId === ad.id ? (
                                                <button className="btn fourthly small" type="button" disabled>Изменяется</button>
                                            ) : (
                                                (ad.status==='ACTIVE') ?
                                                    (
                                                        <>
                                                    <button className="btn fourthly small" type="button" onClick={() => this.setState({ editingId: ad.id, ...ad as any, action: ad.action.toLowerCase(), currency: ad.currency.toLowerCase() })}>Изменить</button>
                                                    {deletingId === ad.id ? (
                                                        <Button
                                                            kind='tertiary'
                                                            small
                                                            type="button"
                                                            onClick={() => this.onDelete(ad.id)}
                                                            onMouseLeave={() => !deleteLoading && this.setState({ deletingId: undefined })}
                                                            loading={deleteLoading}
                                                        >
                                                            Вы уверены?
                                                        </Button>
                                                    ) : (
                                                        <button className="btn tertiary small" type="button" onClick={() => this.setState({ deletingId: ad.id })}>Удалить</button>
                                                    )}
                                                </>

                                                    ) : (<span className="operation__value">Сделка завершена</span>)
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="main-block">
                        <h2 className="operation-title">{editingId ? 'Изменение объявления' : 'Создание объявления'}</h2>
                        <div className="create-wrap">
                            <form className="main-form" onSubmit={editingId ? this.onEdit : this.onCreate}>
                                <div className="main-form__wrap">
                                    <div className="main-form__field main-form__field_sel">
                                        <label className="main-form__label" htmlFor="#">Направление</label>
                                        <select className="main-form__select" name="#" value={action} onChange={e => this.setState({ action: e.target.value as any })}>
                                            <option value="buy">Покупка</option>
                                            <option value="sell">Продажа</option>
                                        </select>
                                    </div>
                                    <div className="main-form__field main-form__field_sel">
                                        <label className="main-form__label" htmlFor="#">Валюта</label>
                                        <select className="main-form__select" name="#" value={currency}
                                                onChange={e => this.setState({ currency: e.target.value as any })}>
                                            <option value="rub">RUB</option>
                                            <option value="kzt">KZT</option>
                                            <option value="uzs">UZS</option>
                                            <option value="aed">AED</option>

                                        </select>
                                    </div>
                                    <div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">Минимальная сумма</label>
                                        <input
                                            className="main-form__input"
                                            type='number'
                                            value={minSum}
                                            min={0}
                                            step={.01}
                                            onChange={e => this.setState({ minSum: e.target.value })}
                                        />
                                    </div>
                                    <div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">Максимальная сумма</label>
                                        <input
                                            className="main-form__input"
                                            type='number'
                                            value={maxSum}
                                            min={minSum}
                                            step={.01}
                                            onChange={e => this.setState({ maxSum: e.target.value })}
                                        />
                                    </div>
                                    <div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">Метод оплаты</label>
                                        <input
                                            className="main-form__input"
                                            type="text"
                                            value={paymentMethod}
                                            onChange={e => this.setState({ paymentMethod: e.target.value })}
                                        />
                                    </div>
                                    <div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">Описание</label>
                                        <textarea
                                            className="main-form__textarea"
                                            name="#"
                                            value={description}
                                            onChange={e => this.setState({ description: e.target.value })}
                                        />
                                    </div>
                                    <div className="main-form__field">
                                        <label className="main-form__label" htmlFor="#">Цена, %</label>
                                        <input
                                            className="main-form__input"
                                            type='number'
                                            value={priceExtraPercent}
                                            min={0}
                                            step={.01}
                                            onChange={e => this.setState({ priceExtraPercent: e.target.value })}
                                        />
                                    </div>
                                    <div className="btn-wrap">
                                        {editingId && <button className="signin" type="submit" onClick={() => this.setState({ editingId: undefined, ...initialState })}>Отмена</button>}
                                        <Button kind='primary' type="submit" loading={loading}>Сохранить</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
