import React from 'react';
import { Link } from 'react-router-dom';

interface IFooterProps {
}

interface IFooterState {
}

export class Footer extends React.Component<IFooterProps, IFooterState> {
    render() {
        return (
            <footer className="footer">
                <div className="container">
                    <Link className="logo" to="/">
                        
                        <div className="bg-logo"><img src={require('../images/Logo_1200.gif')} alt=""/></div>
                    </Link>
                    <div className="contact">
                        <span className="contact__title">Информационный канал биржи</span>
                        <a className="contact__link" href="#">@channel</a>
                    </div>
                    <div className="contact">
                        <span className="contact__title">Чат биржи</span>
                        <a className="contact__link" href="#">@chatexchange</a>
                    </div>
                    <div className="contact">
                        <span className="contact__title">Техподдержка</span>
                        <a className="contact__link" href="#">@help</a>
                    </div>
                    <div className="contact">
                        <span className="contact__title">Курсовой бот</span>
                        <a className="contact__link" href="#">@botinfo</a>
                    </div>
                </div>
            </footer>
        )
    }
}


/*
<div className="bg-logo"><img src={require('../images/Logo_1200.gif')} alt=""/></div>

<div className="bg-logo"><img src={require('../images/logo.png')} alt=""/></div>
<div className="contact">
                            <span className="contact__name">TOP EXCHANGE</span>
                            <span className="base-text">Биржа криптовалюты и токенов</span>
                        </div>
*/