import React from 'react';
import { Modal } from "../components/Modal";
import { Modals } from "../stores/ModalStore";

interface ICalcModalProps {
}

interface ICalcModalState {
}

export class CalcModal extends React.Component<ICalcModalProps, ICalcModalState> {
    render() {
        return (
            <Modal modalKey={Modals.Calc}>
                <form className="main-form" action="#">
                    <div className="main-form__wrap">
                        <div className="main-form__field"><label className="main-form__label" htmlFor="#">BTC</label><input
                            className="main-form__input" type="text"/></div>
                        <div className="main-form__field"><label className="main-form__label" htmlFor="#">CFR</label><input
                            className="main-form__input" type="text"/></div>
                        <div className="main-form__field"><label className="main-form__label" htmlFor="#">RUB</label><input
                            className="main-form__input" type="text"/></div>
                        <button className="btn primary" type="submit">Расчитать</button>
                    </div>
                </form>
            </Modal>
        )
    }
}