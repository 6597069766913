import React from 'react';
import {RouteComponentProps} from "react-router";
import {OtcDeal} from "../../utils/graphql";
import {resolve} from "inversify-react";
import {Api} from "../../utils/api";
import {deserialize} from "typescript-json-serializer";
import {ChatMessage} from "../../utils/types";
import classNames from "classnames";
import {DateTime} from "luxon";
import {AuthStore} from "../../stores";
import {pd, processRequestError} from "../../utils/utilities";
import TextareaAutosize from 'react-textarea-autosize';
import {Button} from "../../components/Button";
import {fromEvent} from "file-selector";
import {FileWithPath} from "file-selector/src/file";

interface RouteParams {
    did: string;
}

interface IOTCDealChatProps extends RouteComponentProps<RouteParams> {
}

interface IOTCDealChatState {
    deal?: OtcDeal;
    messages: ChatMessage[];
    messageInput: string,
    baseSum: string;
    actualSum: string;
    loading: boolean;
}

export class OTCDealChat extends React.Component<IOTCDealChatProps, IOTCDealChatState> {
    private messagesScroll: HTMLDivElement;
    private fileInput: HTMLInputElement;

    @resolve(Api)
    declare protected readonly api: Api;
    @resolve(AuthStore)
    declare protected readonly authStore: AuthStore;

    state: IOTCDealChatState = {
        messages: [],
        messageInput: '',
        baseSum: '',
        actualSum: '',
        loading: false,
    };

    componentDidMount() {
        this.loadDeal();
        this.authStore.websocket.addEventListener('message', this.onMessage);
        if (this.authStore.websocket.readyState == WebSocket.CONNECTING)
            this.authStore.websocket.addEventListener('open', this.getMessages);
        else
            this.getMessages();
    }

    getMessages = () => {
        this.authStore.websocket.send(JSON.stringify({
            action: 'get_messages',
            type: 'deal',
            object_id: this.props.match.params.did
        }));
    }

    componentWillUnmount() {
        this.authStore.websocket.removeEventListener('message', this.onMessage);
        this.authStore.websocket.removeEventListener('open', this.getMessages);
    }

    addMessages = async (messages: ChatMessage[]) => {
        const needRefresh = messages.some(m => m.service);
        messages = messages.filter(m => m.deal_id.toString() === this.props.match.params.did);
        messages = messages.concat(this.state.messages);
        const messageIds = {}
        messages.forEach(m => messageIds[m.pk] = m);
        messages = Object.values(messageIds);
        messages.sort((a, b) => a.pk < b.pk ? -1 : 1);
        const needScroll = this.messagesScroll.scrollHeight - this.messagesScroll.scrollTop === this.messagesScroll.clientHeight;
        this.setState({messages}, () => needScroll && this.messagesScroll.scrollTo({top: 99999999}));
        if (needRefresh)
            await this.loadDeal();
    }

    onMessage = (e: MessageEvent) => {
        const data = JSON.parse(e.data);

        if (data.action === 'history') {
            const messages: ChatMessage[] = data.messages.map(m => deserialize(m, ChatMessage));
            this.addMessages(messages)
        } else if (data.action === 'new_message') {
            const message: ChatMessage = deserialize(data.message, ChatMessage);
            this.addMessages([message]);
        }
    }

    onSendMessage = (e: React.FormEvent) => {
        e && pd(e);
        this.authStore.websocket.send(JSON.stringify({
            action: 'send_message',
            type: 'deal',
            object_id: this.props.match.params.did,
            text: this.state.messageInput,
        }))
        this.setState({messageInput: ''});
    }

    attachFile = async (e) => {
        const files = await fromEvent(e);
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.authStore.websocket.send(JSON.stringify({
                action: 'send_message',
                type: 'deal',
                object_id: this.props.match.params.did,
                text: this.state.messageInput,
                attachment: reader.result,
            }))
            this.setState({messageInput: ''});
        });
        reader.readAsDataURL(files[0] as FileWithPath);
    }

    onOffer = async (e: React.FormEvent) => {
        pd(e);
        this.setState({loading: true});
        try {
            await this.api.otcDealOffer(this.props.match.params.did, this.state.baseSum);
        } catch (e) {
            processRequestError(e)
        } finally {
            this.setState({loading: false});
        }
        await this.loadDeal();
    }

    onAccept = async (e: React.FormEvent) => {
        pd(e);
        this.setState({loading: true});
        try {
            await this.api.otcDealAccept(this.props.match.params.did);
        } catch (e) {
            processRequestError(e)
        } finally {
            this.setState({loading: false});
        }
        await this.loadDeal();
    }

    onDecline = async (e: React.FormEvent) => {
        pd(e);
        this.setState({loading: true});
        try {
            await this.api.otcDealDecline(this.props.match.params.did);
        } catch (e) {
            processRequestError(e)
        } finally {
            this.setState({loading: false});
        }
        await this.loadDeal();
    }

    onPaid = async (e: React.FormEvent) => {
        pd(e);
        this.setState({loading: true});
        try {
            await this.api.otcDealPaid(this.props.match.params.did);
        } catch (e) {
            processRequestError(e)
        } finally {
            this.setState({loading: false});
        }
        await this.loadDeal();
    }

    onComplete = async (e: React.FormEvent) => {
        pd(e);
        this.setState({loading: true});
        try {
            await this.api.otcDealComplete(this.props.match.params.did);
        } catch (e) {
            processRequestError(e)
        } finally {
            this.setState({loading: false});
        }
        await this.loadDeal();
    }

    loadDeal = async () => {
        this.setState({deal: await this.api.getDeal(this.props.match.params.did)});
    }

    render() {
        const {deal, messages, messageInput, baseSum, actualSum} = this.state;
        const ad = deal?.ad;

        const otherUserName = deal?.user.id === this.authStore.profile?.id ? ad?.user.name : deal?.user.name;
        const isDealAuthor = deal?.user.id === this.authStore.profile?.id;
        const isSeller = deal?.ad.action === 'SELL' && !isDealAuthor || deal?.ad.action === 'BUY' && isDealAuthor;

        return (
            <div className="tabs__content active">
                <div className="main-content">
                    <div className="main-block">
                        <h2 className="operation-title">Название объявления</h2>
                        <div className="operation">
                            <div className="operation__wrap">
                                <div className="operation__line">
                                    <div className="operation__block">
                                        <span
                                            className="operation__title">{ad?.action === 'SELL' ? 'Продавец' : 'Покупатель'}</span>
                                        <span className="operation__value">{ad?.user.name} ({ad?.user.id})</span>
                                        {/*<span className="operation__status">доверенный</span>*/}
                                    </div>
                                    <div className="operation__block">
                                        <span className="operation__title">Метод оплаты</span>
                                        <span className="operation__value">{ad?.paymentMethod}</span>
                                    </div>
                                </div>
                                <div className="operation__line">
                                    <div className="operation__block">
                                        <span className="operation__title">Цена</span>
                                        <span className="operation__value">{ad?.priceExtraPercent}%</span>
                                    </div>
                                    <div className="operation__block">
                                        <span className="operation__title">Сумма</span>
                                        <span
                                            className="operation__value">{ad?.minSum} - {ad?.maxSum} {ad?.currency}</span>
                                        {/*<span className="operation__value operation__value_sub">516.51 - 1032.02 (CFR)</span>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bye-sell">
                            {deal?.state === 'CREATED' && (
                                isDealAuthor ? (
                                    <form className="main-form" onSubmit={this.onOffer}>
                                        <div className="main-form__wrap">
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Сумма к
                                                    получению</label>
                                                <input
                                                    className="main-form__input"
                                                    type="number"
                                                    min={ad?.minSum}
                                                    max={ad?.maxSum}
                                                    step={.01}
                                                    value={baseSum}
                                                    onChange={e => this.setState({
                                                        baseSum: e.target.value,
                                                        actualSum: (parseFloat(e.target.value) * (1 + parseFloat(ad?.priceExtraPercent) / 100)).toString()
                                                    })}
                                                />
                                            </div>
                                            <div className="main-form__field">
                                                <label className="main-form__label" htmlFor="#">Будет снято с
                                                    баланса</label>
                                                <input
                                                    className="main-form__input"
                                                    type="number"
                                                    min={ad?.minSum * (1 + parseFloat(ad?.priceExtraPercent) / 100)}
                                                    max={ad?.maxSum * (1 + parseFloat(ad?.priceExtraPercent) / 100)}
                                                    step={.01}
                                                    value={actualSum}
                                                    onChange={e => this.setState({
                                                        actualSum: e.target.value,
                                                        baseSum: (parseFloat(e.target.value) / (1 + parseFloat(ad?.priceExtraPercent) / 100)).toString()
                                                    })}
                                                />
                                            </div>
                                            <Button kind="primary"
                                                    type="submit">{ad?.action === 'SELL' ? 'Купить' : 'Продать'}</Button>
                                        </div>
                                    </form>
                                ) : (
                                    <div className='status-text'>Ожидание предложения суммы...</div>
                                )
                            )}
                            {deal?.state === 'OFFERED' && (
                                isDealAuthor ? (
                                    <div className='status-text'>Ожидание подтверждения суммы...</div>
                                ) : (
                                    <form className="main-form" onSubmit={this.onAccept}>
                                        <div className='status-text'>Предложенная сумма: {deal?.amount}</div>
                                        <div className="main-form__wrap">
                                            <div className="btn-wrap_">
                                                <Button kind="secondary" type="button"
                                                        onClick={this.onDecline}>Отклонить</Button>
                                                <Button kind="primary" type="submit">Принять сумму</Button>
                                            </div>
                                        </div>
                                    </form>
                                )
                            )}
                            {deal?.state === 'ACCEPTED' && (
                                isSeller ? (
                                    <div className='status-text'>Ожидание оплаты...</div>
                                ) : (
                                    <form className="main-form" onSubmit={this.onPaid}>
                                        <div
                                            className='status-text'>Оплатите {parseFloat(deal?.amount) * (1 + parseFloat(ad?.priceExtraPercent) / 100)}</div>
                                        <div className="main-form__wrap">
                                            <Button kind="primary" type="submit">Оплатил</Button>
                                        </div>
                                    </form>
                                )
                            )}
                            {deal?.state === 'PAID' && (
                                isSeller ? (
                                    <form className="main-form" onSubmit={this.onComplete}>
                                        <div className='status-text'>Оплатите {deal?.amount}</div>
                                        <div className="main-form__wrap">
                                            <Button kind="primary" type="submit">Оплата получена</Button>
                                        </div>
                                    </form>
                                ) : (
                                    <div className='status-text'>Ожидание подтверждения получения оплаты...</div>
                                )
                            )}
                            {deal?.state === 'COMPLETE' && (
                                <div className='status-text'>Сделка завершена</div>
                            )}
                            {deal?.state === 'CANCELED' && (
                                <div className='status-text'>Сделка отклонена</div>
                            )}
                        </div>
                    </div>
                    <div className="main-block">
                        <div className="chat">
                            <div className="chat__list-wrap">
                                <h2 className="operation-title">{otherUserName}</h2>
                                <div ref={ref => this.messagesScroll = ref}
                                     className="chat__message-content scroll-wrap">
                                    {messages.map(m => (
                                        <div className={classNames('chat__message', {my: !m.incoming})} key={m.pk}>
                                            <div className="chat__info-line">
                                                <span className="chat__text">{m.incoming ? otherUserName : 'Вы'}</span>
                                                <span
                                                    className="chat__text">{m.datetime.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</span>
                                            </div>
                                            <div className="chat__message-bg">
                                                <p className={classNames('chat__base-text', {service: m.service})}>{m.text}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <form className="main-form" onSubmit={this.onSendMessage}>
                                    <input type='file' ref={ref => this.fileInput = ref} onChange={this.attachFile}
                                           style={{display: 'none'}}/>
                                    <div className="main-form__wrap">
                                        <div className="main-form__field">
                                            <label className="main-form__label" htmlFor="#"/>
                                            <TextareaAutosize
                                                minRows={1}
                                                maxRows={5}
                                                className="main-form__textarea"
                                                name="#"
                                                placeholder="Напишите ваше сообщение"
                                                value={messageInput}
                                                onKeyDown={e => e.key === 'Enter' && !e.shiftKey && this.onSendMessage(e)}
                                                onChange={e => this.setState({messageInput: e.target.value})}
                                            />
                                            <button className="main-form__file" type="button"
                                                    onClick={() => this.fileInput.click()}>
                                                <svg>
                                                    <use href="#file"/>
                                                </svg>
                                            </button>
                                            <button className="btn primary send" type="submit">
                                                <svg>
                                                    <use href="#send"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
