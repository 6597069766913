import React, { FC, useState, useEffect } from 'react';
import classNames from "classnames";
import { observer } from 'mobx-react-lite';
import { rootStore } from "../../Application";
import { tf, tf2,normalizeVolume, PrecisionCount } from "../../utils/utilities";
import { Button } from "../../components/Button";
import { BalanceExchangeType, CoinType } from "../../utils/graphql";
import { toast } from "react-toastify";
import { processRequestError } from "../../utils/utilities";
interface IFormBuyProps {
    currentCoin: CoinType;
    type: string;
}


const FormSell: FC<IFormBuyProps> = ({ currentCoin, type = "market" }) => {
    const [currentRate, setCurrentRate] = useState<number>(1)
    const [inputBuy, setInputBuy] = useState<number | string>(null)
    const [inputTurnoverBuy, setInputTurnoverBuy] = useState<number | string>(null)

    // @ts-ignore
    useEffect(async () => {
        await rootStore.coinStore.getBalancesOne(currentCoin.coinSymbol)
        //await setCurrentRate(Number(tf(currentCoin.rubRate, currentCoin.baseCoinScale)))
        //await setInputTurnoverBuy(Number(tf(currentCoin.rubRate * inputBuy, currentCoin.baseCoinScale)))

    }, []);

    // @ts-ignore
    useEffect(async () => {
        validateOutput('')
        validateInput('')
        //validateInput(0)
    }, [type]);


 // @ts-ignore
 useEffect(async () => {
        if (type=="market") {
            if (rootStore.ordersStore.selectSell) {
            await setInputTurnoverBuy(Number(tf(rootStore.ordersStore.selectSell.turnovers, currentCoin.baseCoinScale)))
            await setCurrentRate(Number(tf(rootStore.ordersStore.selectSell.price, currentCoin.baseCoinScale)))
            await setInputBuy(Number(tf(rootStore.ordersStore.selectSell.amounts, currentCoin.coinScale)))
            }
        }
     }, [rootStore.ordersStore.selectSell]);

    // @ts-ignore
    useEffect(async () => {
        await rootStore.coinStore.getBalancesOne(currentCoin.coinSymbol)
        await setCurrentRate(Number(tf(currentCoin.rubRate, currentCoin.baseCoinScale)))
        await setInputBuy('')
        await setInputTurnoverBuy('')
    }, [currentCoin]);

    const handleKeyPress = (event) => {
        // Разрешаем: backspace, delete, tab и escape       
        let eventNative = event.nativeEvent
        if (eventNative.keyCode == 44 || eventNative.keyCode == 46 || eventNative.keyCode == 8 || eventNative.keyCode == 9 || eventNative.keyCode == 27 ||
            // Разрешаем: Ctrl+A
            (eventNative.keyCode == 65 && eventNative.ctrlKey === true) ||
            // Разрешаем: home, end, влево, вправо
            (eventNative.keyCode >= 35 && eventNative.keyCode <= 39)) {
            // Ничего не делаем
            return;
        } else {
            // Запрещаем все, кроме цифр на основной клавиатуре, а так же Num-клавиатуре
            if ((eventNative.keyCode < 48 || eventNative.keyCode > 57)) {
                event.preventDefault();
            }
        }
    }
    const setMax = () => {
        //console.log(type)
        if (type=='limit')
            validateInput(getMax())
            else 
            validateInput(getMaxMarket())
    }

    const validateOutput = async (newValue) => {
        let min = 0;
        let max = currentRate * getMax()

        if (String(newValue).length==0 || newValue==null) {
            await setInputTurnoverBuy('')
            await setInputBuy('')
            
            return
        }


        if (newValue !== true && isNaN(newValue)) {
            //console.log("55",newValue !== true,isNaN(newValue))
            newValue = min
        }

        if (Number(newValue) <= Number(min)) {
            newValue = min
        }

        if (Number(newValue) >= Number(max)) {
            newValue = max
        }

        if (!/^\d+(\.?\,?\d*)?$/.test(newValue) || newValue < 0) {
            newValue = '0';
        }

      
            let currend = normalizeVolume(+newValue, getStepOutput())
            let rate = currentRate;
            if (type=="market")  rate = getPriceForOutputMarket(currend) 
            if ((newValue.toString().includes('.') && newValue.toString().endsWith('0')) || newValue.toString().endsWith('.') || newValue === '0') {
                if (PrecisionCount(newValue) > currentCoin.baseCoinScale) {
                    newValue = currend
                }
                if (type == "limit") setInputTurnoverBuy(newValue); else setInputTurnoverBuy(newValue)
                    console.log("--",rate,currend,  1/ rate * currend, currentCoin.baseCoinScale,(tf2(1 / rate*newValue,currentCoin.coinScale)));   
                setInputBuy(Number(tf2(1 / rate * newValue, currentCoin.coinScale)))
            } else {
                console.log("-",rate,currend,  1 / rate * currend, currentCoin.baseCoinScale,(tf2(1 / rate *currend,currentCoin.coinScale)));   
                if (type == "limit") setInputTurnoverBuy(currend); else setInputTurnoverBuy(currend)
                setInputBuy(Number(tf2(1 / rate * currend, currentCoin.coinScale)))
            }
        


    }

    const validateInput = async (newValue) => {
        let min = 0;
        let max = getMax()
        if (String(newValue).length==0 || newValue==null) {
            await setInputTurnoverBuy('')
            await setInputBuy('')
            return
        }

        if (newValue !== true && isNaN(newValue)) {
            //console.log("55",newValue !== true,isNaN(newValue))
            newValue = min
        }

        if (Number(newValue) <= Number(min)) {
            newValue = min
        }

        if (Number(newValue) >= Number(max)) {
            newValue = max
        }

        if (!/^\d+(\.?\,?\d*)?$/.test(newValue) || newValue < 0) {
            newValue = '0';
        }
       
        let currend = normalizeVolume(+newValue, getStep())
        let rate = currentRate;
        if (type=="market")  rate = getPriceForInputMarket(currend) 

        if ((newValue.toString().includes('.') && newValue.toString().endsWith('0')) || newValue.toString().endsWith('.') || newValue === '0') {
                if (PrecisionCount(newValue) > currentCoin.coinScale) {
                    newValue = currend
                }
                console.log("!--",rate,currend, rate * currend, currentCoin.baseCoinScale,(tf(rate * newValue, currentCoin.baseCoinScale)));   
                setInputBuy(newValue)
                if (type == "limit") setInputTurnoverBuy(Number(tf(rate * newValue, currentCoin.baseCoinScale))); else setInputTurnoverBuy(Number(tf(rate * newValue, currentCoin.baseCoinScale)))
            } else {
                setInputBuy(currend)
                console.log("!-",rate,currend, rate * currend, currentCoin.baseCoinScale,(tf(rate * currend, currentCoin.baseCoinScale)));   
                if (type == "limit") setInputTurnoverBuy(Number(tf(rate * currend, currentCoin.baseCoinScale))); else setInputTurnoverBuy(Number(tf(rate * currend, currentCoin.baseCoinScale)))

        }
        
    }


    const getPriceForInputMarket = (Value) => {
        let amount = 0;
        let turnover = 0;
        let price = null;
      
        rootStore.ordersStore.buy.map(one=>{

                if (amount < Value) {
                    amount +=Number(one.amounts)
                    turnover +=Number(one.turnovers) 
                }
        })

        price = tf2(turnover/amount,currentCoin.baseCoinScale)
        if (price>0) setCurrentRates(price)
        if (price>0) return price; else return currentRate
    }

    const getPriceForOutputMarket = (Value) => {
        let amount = 0;
        let turnover = 0;
        let price = null;
        rootStore.ordersStore.buy.map(one=>{
                if (turnover< Value) {
                    amount +=Number(one.amounts)
                    turnover +=Number(one.turnovers) 
                }
        })

        price = tf2(turnover/amount,currentCoin.baseCoinScale)
        if (price>0)  setCurrentRates(price)
        if (price>0) return price; else return currentRate
    }

    const setCurrentRates = (newValue) => {
        let currend = normalizeVolume(+newValue, getStepOutput())
        if ((newValue.includes('.') && newValue.endsWith('0')) || newValue.endsWith('.') || newValue === '0') {
            if (PrecisionCount(newValue) > currentCoin.baseCoinScale) {
                newValue = currend
            }
            setCurrentRate(newValue)
            setInputTurnoverBuy(Number(tf(Number(inputBuy) * newValue, currentCoin.baseCoinScale)))
           
        } else {
            setCurrentRate(currend)
            setInputTurnoverBuy(Number(tf(Number(inputBuy) * currend, currentCoin.baseCoinScale)))
    
        }
    }

    const getStep = () => {
        if (currentCoin) {
            return 1 / Math.pow(10, currentCoin.coinScale)
        } else {
            return 0.1
        }
    }

    const getStepOutput = () => {
        if (currentCoin) {
            return 1 / Math.pow(10, currentCoin.baseCoinScale)
        } else {
            return 0.1
        }
    }
    const getMaxMarket = () => {
        let max = 0;
        rootStore.ordersStore.buy.map(one => {
            max += Number(one.amounts)
        })

        //console.log(max, Number(rootStore.coinStore.balanceSell.balance))
        if (max < Number(rootStore.coinStore.balanceSell.balance)) {
            return max;
        } else {
            if (rootStore.coinStore.balanceSell) {
                return rootStore.coinStore.balanceSell.balance
            } else return 1
        }
    }
    const getMax = () => {
                //console.log(rootStore.coinStore.balanceSell.balance)   
            if (rootStore.coinStore.balanceSell) {
                return rootStore.coinStore.balanceSell.balance
            } else return 1
    
    }

    const onCreateOrder = async () => {
        try {

            if (type == "limit") {
                //console.log(inputBuy, getMax())
                if (Number(inputBuy) <= 0 || inputBuy > getMax()) {
                    throw new Error("Не верно указано количество")
                }
                //console.log(currentCoin.symbol, "BUY", type, inputBuy, currentRate, inputTurnoverBuy)
                await rootStore.ordersStore.createOrder(currentCoin.symbol, "SELL", type, Number(inputBuy), currentRate, Number(inputTurnoverBuy))
            } else {
                if (Number(inputBuy) <= 0 || inputBuy > getMax()) {
                    throw new Error("Не верно указан объем ордера")
                }
                await rootStore.ordersStore.createOrder(currentCoin.symbol, "SELL", type, Number(inputBuy), 0, Number(inputTurnoverBuy))
            }


            await rootStore.coinStore.getBalancesOne(currentCoin.coinSymbol)
            await rootStore.ordersStore.getOrdersCurrent(currentCoin.symbol, 10, 1)
            await rootStore.ordersStore.getOrdersHistory(currentCoin.symbol, 10, 1)

            toast.success('Ордер создан');
        } catch (e) {
            processRequestError(e);
            console.log(e)
        }
    }
    return (
        <div className="main-block">
            <div className="balance-head">
                <h2 className="operation-title">Продать {currentCoin?.coinSymbol} </h2>
                {rootStore.coinStore.balanceSell && <span
                    className="balance">{tf(rootStore.coinStore.balanceSell?.balance, currentCoin.coinScale)} {currentCoin?.coinSymbol}</span>}
                {rootStore.coinStore.balanceSell && <span
                    className="balance cold">{tf(rootStore.coinStore.balanceSell?.freezeBalance, currentCoin.coinScale)} {currentCoin?.coinSymbol} </span>}
            </div>
            <div className="bye-sell">
                <form className="main-form" action="#">
                    <div className="main-form__wrap">
                        <div className="main-form__field">
                            <label className="main-form__label"
                                htmlFor="#">Количество</label>
                            <div className="main-form__input-wrap">
                                <input className="main-form__input"
                                    type="text"
                                    value={inputBuy}
                                    step={getStep()}
                                    placeholder={currentCoin?.coinSymbol}
                                    onKeyPress={handleKeyPress}
                                    onChange={e => validateInput(e.target.value)}
                                />
                            </div>
                            <button className="main-form__max" onClick={() => setMax()} type="button">MAX
                            </button>
                        </div>
                        <div className="main-form__field">
                            <label className="main-form__label"
                                htmlFor="#">Курс</label>

                            <div className="main-form__input-wrap">
                                <input className="main-form__input"
                                    type="text"
                                    disabled={type == "market"}
                                    value={currentRate}
                                    onKeyPress={handleKeyPress}
                                    step={getStepOutput()}
                                    onChange={e => setCurrentRates(e.target.value)}
                                />
                                <span
                                    className="main-form__subinfo">{currentCoin?.baseSymbol}</span>
                            </div>
                        </div>
                        <div className="main-form__field">
                            <label className="main-form__label"
                                htmlFor="#">Всего</label>
                            <div className="main-form__input-wrap">
                                <input
                                    className="main-form__input"
                                    type="text"
                                    value={inputTurnoverBuy}
                                  
                                    placeholder={currentCoin?.baseSymbol}
                                    step={getStepOutput()}
                                    onKeyPress={handleKeyPress}
                                    onChange={e => validateOutput(e.target.value)}
                                />

                            </div>
                            <button className="main-form__max" onClick={() => setMax()}
                                type="button">MAX
                            </button>
                        </div>
                        <button className="btn tertiary" onClick={async () => await onCreateOrder()}
                            type="button">Продать
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default observer(FormSell)
