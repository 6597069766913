import React, {FC, useState, useEffect} from 'react';

import {observer} from 'mobx-react-lite';
import {rootStore} from "../../Application";
import {tf,tf2, normalizeVolume, PrecisionCount} from "../../utils/utilities";
import {processRequestError} from "../../utils/utilities";
import {BalanceExchangeType, CoinType} from "../../utils/graphql";
import { toast } from "react-toastify"

interface IFormBuyProps {
    currentCoin: CoinType;
    type:string;
}

@observer
const FormBuy: FC<IFormBuyProps> = ({currentCoin,type="market"}) => {
    const [currentRate, setCurrentRate] = useState<number>(1)
    const [inputBuy, setInputBuy] = useState<number | string> (null)
    const [inputTurnoverBuy, setInputTurnoverBuy] = useState<number | string>(null)


    // @ts-ignore
    useEffect(async () => {

        await rootStore.coinStore.getBalancesBuyOne(currentCoin.baseSymbol)
        //await setCurrentRate(Number(tf(currentCoin.rubRate, currentCoin.baseCoinScale)))
       //await setInputTurnoverBuy(Number(tf(currentCoin.rubRate * inputBuy, currentCoin.baseCoinScale)))
    }, []);

    // @ts-ignore
    useEffect(async () => {
        console.log("type useEffect BUY",inputBuy,inputTurnoverBuy)
        await setInputBuy('')
        await setInputTurnoverBuy('') 
     //  validateOutput(null)
     //  validateInput(null)
       
    }, [type]);

    // @ts-ignore
    useEffect(async () => {
        await rootStore.coinStore.getBalancesBuyOne(currentCoin.baseSymbol)
        await setCurrentRate(Number(tf(currentCoin.rubRate, currentCoin.baseCoinScale)))
        await setInputTurnoverBuy('')
        await setInputBuy('')
    }, [currentCoin]);

    // @ts-ignore
    useEffect(async () => {
        if (type=="market") {
            console.log("selectBuy",rootStore.ordersStore.selectBuy)
            if (rootStore.ordersStore.selectBuy) {
                console.log("selectBuy",rootStore.ordersStore.selectBuy.price,Number(tf(rootStore.ordersStore.selectBuy.price, currentCoin.baseCoinScale)))
                await setInputTurnoverBuy(Number(tf(rootStore.ordersStore.selectBuy.turnovers, currentCoin.baseCoinScale)))
                await setCurrentRate(Number(tf(rootStore.ordersStore.selectBuy.price, currentCoin.baseCoinScale)))
               await setInputBuy(Number(tf(rootStore.ordersStore.selectBuy.amounts, currentCoin.coinScale)))
            }
        }

     }, [rootStore.ordersStore.selectBuy]);

    const handleKeyPress = (event) => {
        // Разрешаем: backspace, delete, tab и escape
        let eventNative = event.nativeEvent

        if (eventNative.keyCode == 44 || eventNative.keyCode == 46 || eventNative.keyCode == 8 || eventNative.keyCode == 9 || eventNative.keyCode == 27 ||
            // Разрешаем: Ctrl+A
            (eventNative.keyCode == 65 && eventNative.ctrlKey === true) ||
            // Разрешаем: home, end, влево, вправо
            (eventNative.keyCode >= 35 && eventNative.keyCode <= 39)) {
            // Ничего не делаем
            return;
        } else {
            // Запрещаем все, кроме цифр на основной клавиатуре, а так же Num-клавиатуре
            if ((eventNative.keyCode < 48 || eventNative.keyCode > 57)) {
                event.preventDefault();
            }
        }
    }

    const validateOutput = async (newValue) => {
        let min = 0;
        let max = getMax();

        if (String(newValue).length==0 || newValue==null) {
       
            await setInputTurnoverBuy('')
            await setInputBuy('')
            return
        }

       
        if (newValue !== true && isNaN(newValue)) {
            ///console.log("55",newValue !== true,isNaN(newValue))   
            newValue = min  
        }

        if (Number(newValue) <= Number(min)) {
            newValue = min
        }
        if (Number(newValue) >= Number(max)) {
            newValue = max
        }

        if (!/^\d+(\.?\,?\d*)?$/.test(newValue) || newValue < 0) {
            newValue = '0';
        }

        let currend = normalizeVolume(+newValue, getStepOutput())
        let rate = currentRate;
        if (type=="market") rate = getPriceForOutputMarket(currend) 


        if ((newValue.toString().includes('.') && newValue.toString().endsWith('0')) || newValue.toString().endsWith('.') || newValue === '0') {
            if (PrecisionCount(newValue) > currentCoin.baseCoinScale) {
                newValue = currend
            }

            setInputTurnoverBuy(newValue)
            console.log(rate,currend, 1/ rate * currend, currentCoin.coinScale,(tf2(1 / rate * currend, currentCoin.coinScale)));   
            //if (type=="limit") 
            setInputBuy(Number(tf2(1 / rate * newValue, currentCoin.coinScale)))
        } else {
            console.log("-",rate,currend, 1/ rate * currend, currentCoin.coinScale,(tf2(1 / rate * currend, currentCoin.coinScale)));      
            setInputTurnoverBuy(currend)
            //if (type=="limit") 
            setInputBuy(Number(tf2(1 / rate * currend, currentCoin.coinScale)))
        }

    }
   

    const validateInput = async (newValue) => {
        let min = 0;
        let max = 1 / currentRate * getMax()

        
        if (String(newValue).length==0 || newValue==null) {
            newValue = null;
            
            await setInputBuy('')
            await setInputTurnoverBuy('')
       
            return
        }

        
        if (newValue !== true && isNaN(newValue)) {
            //console.log("55",newValue !== true,isNaN(newValue))
            newValue = min
        }

        if (Number(newValue) <= Number(min)) {
            newValue = min
        }

        if (Number(newValue) >= Number(max)) {
            newValue = max
        }

        if (!/^\d+(\.?\,?\d*)?$/.test(newValue) || newValue < 0) {
            newValue = '0';
        }

       
      
        let currend = normalizeVolume(+newValue, getStep())
        let rate = currentRate;
        if (type=="market")  rate = getPriceForInputMarket(currend) 
            console.log(rate,currend)
           
        if ((newValue.toString().includes('.') && newValue.toString().endsWith('0')) || newValue.toString().endsWith('.') || newValue === '0') {
                if (PrecisionCount(newValue) > currentCoin.coinScale) {
                    newValue = currend
                }
                console.log(rate * newValue, currentCoin.baseCoinScale,Number(tf(rate * newValue, currentCoin.baseCoinScale)))   
                if (type=="limit") setInputBuy(newValue); else setInputBuy(currend)
                setInputTurnoverBuy(Number(tf(rate * newValue, currentCoin.baseCoinScale)))

        } else {
                console.log("-",rate,currend, rate * currend, currentCoin.baseCoinScale,(tf(rate * currend, currentCoin.baseCoinScale)));   
                if (type=="limit")  setInputBuy(currend);  else setInputBuy(currend)
                setInputTurnoverBuy(Number(tf(rate * currend, currentCoin.baseCoinScale)))
        }
        
    }
    const getPriceForInputMarket = (Value) => {
        let amount = 0;
        let turnover = 0;
        let price = null;
      
        rootStore.ordersStore.sell.map(one=>{

                if (amount < Value) {
                    amount += Number(one.amounts)
                    turnover += Number(one.turnovers) 
                }
        })

        price = tf2(turnover/amount, currentCoin.baseCoinScale)
        if (price>0) setCurrentRates(price)
       
        if (price>0) return price; else return currentRate
    }

    const getPriceForOutputMarket = (Value) => {
        let amount = 0;
        let turnover = 0;
        let price = null;
        rootStore.ordersStore.sell.map(one=>{
                if (turnover< Value) {
                    amount +=Number(one.amounts)
                    turnover +=Number(one.turnovers) 
                }
        })

        price = tf2(turnover/amount,currentCoin.baseCoinScale)
        if (price>0)  setCurrentRates(price)
       
        if (price>0)  return price; else return currentRate 
    }
    const setCurrentRates = (newValue) => {

       
        let currend = normalizeVolume(+newValue, getStepOutput())
        if ((newValue.includes('.') && newValue.endsWith('0')) || newValue.endsWith('.') || newValue === '0') {
            if (PrecisionCount(newValue) > currentCoin.baseCoinScale) {
                newValue = currend
            }
            setCurrentRate(newValue)
            setInputTurnoverBuy(Number(tf(Number(inputBuy) * newValue, currentCoin.baseCoinScale)))
        } else {
            setCurrentRate(currend)
            setInputTurnoverBuy(Number(tf(Number(inputBuy) * currend, currentCoin.baseCoinScale)))
        }
    }

    const getStep = () => {
        if (currentCoin) {
            return 1 / Math.pow(10, currentCoin.coinScale)
        } else {
            return 0.1
        }
    }

    const setMax = () => {
        if (type=='limit')
        validateOutput(getMax())
        else 
        validateOutput(getMaxMarket())
    }
    const   getMax= () => {
            if (rootStore.coinStore.balanceBuy) {
                return rootStore.coinStore.balanceBuy.balance
            } else return  1
    }

    const  getMaxMarket = () => {
        let max= 0;
        rootStore.ordersStore.sell.map(one=>{
            max += Number(one.turnovers)
        })

        //console.log(max,Number(rootStore.coinStore.balanceBuy.balance))
        if (max<Number(rootStore.coinStore.balanceBuy.balance)) {
            return max;
        } else {
            if (rootStore.coinStore.balanceBuy) {
                return rootStore.coinStore.balanceBuy.balance
            } else return  1
        }
    }

    const getStepOutput = () => {
        if (currentCoin) {
            return 1 / Math.pow(10, currentCoin.baseCoinScale)
        } else {
            return 0.1
        }
    }

    const  onCreateOrder = async () => {
            try {

                if (type=="limit") {
                    console.log(inputTurnoverBuy,getMax())
                    if (Number(inputTurnoverBuy)<=0 || inputTurnoverBuy>getMax()) {
                        throw new Error("Не верно указано количество")
                    }
                    console.log(currentCoin.symbol,"BUY",type,inputBuy,currentRate,inputTurnoverBuy)
                    await rootStore.ordersStore.createOrder(currentCoin.symbol,"BUY",type,Number(inputBuy),currentRate,Number(inputTurnoverBuy))
                } else {
                    if (Number(inputTurnoverBuy)<=0 || inputTurnoverBuy>getMax()) {
                        throw new Error("Не верно указан объем ордера")
                    }
                    await rootStore.ordersStore.createOrder(currentCoin.symbol,"BUY",type,Number(inputBuy),0,Number(inputTurnoverBuy))
                }


                await rootStore.coinStore.getBalancesBuyOne(currentCoin.baseSymbol)
                await rootStore.ordersStore.getOrdersCurrent(currentCoin.symbol, 10, 1)
                await rootStore.ordersStore.getOrdersHistory(currentCoin.symbol, 10, 1)

                toast.success('Ордер создан');
            } catch (e) {
                processRequestError(e);
                console.log(e)
              
            }
    }

    return (
        <div className="main-block">
            <div className="balance-head">
                <h2 className="operation-title">Купить {currentCoin?.coinSymbol}</h2>
                <span
                    className="balance">{tf(rootStore.coinStore.balanceBuy?.balance, currentCoin.baseCoinScale)} {currentCoin?.baseSymbol}</span>
                <span className="balance cold">{tf(rootStore.coinStore.balanceBuy?.freezeBalance, currentCoin.baseCoinScale)}{currentCoin?.baseSymbol}</span>
            </div>
            <div className="bye-sell">
                <form className="main-form" action="#">
                    <div className="main-form__wrap">

                                <div className="main-form__field">
                                    <label className="main-form__label"
                                           htmlFor="#">Количество</label>
                                    <div className="main-form__input-wrap">
                                        <input className="main-form__input"
                                             
                                               type="text"
                                               value={inputBuy}
                                               step={getStep()}
                                               onKeyPress={handleKeyPress}
                                               placeholder={currentCoin?.coinSymbol}
                                               onChange={e =>  validateInput(e.target.value)}
                                        />
                                    </div>
                                    <button className="main-form__max"    onClick={() => setMax()} type="button">MAX
                                    </button>
                                </div>


                                <div className="main-form__field">
                                    <label className="main-form__label"
                                           htmlFor="#">Курс</label>
                                    <div className="main-form__input-wrap">
                                        <input className="main-form__input"
                                               type="text"
                                               disabled={type=="market"}
                                               value={currentRate}
                                               onKeyPress={handleKeyPress}
                                               
                                               step={getStepOutput()}
                                               onChange={e => setCurrentRates(e.target.value)}
                                        />
                                        <span
                                            className="main-form__subinfo" >{currentCoin?.baseSymbol}</span>
                                    </div>
                                </div>

                        <div className="main-form__field">
                            <label className="main-form__label"
                                   htmlFor="#">Всего</label>
                            <div className="main-form__input-wrap">
                                <input
                                    className="main-form__input"
                                    type="text"
                                    value={inputTurnoverBuy}
                                    placeholder={currentCoin?.baseSymbol}
                                    step={getStepOutput()}
                                    onKeyPress={handleKeyPress}
                               
                                    onChange={e => validateOutput(e.target.value)}
                                />
                            </div>
                            <button className="main-form__max"  onClick={() => setMax()} type="button">MAX
                            </button>
                        </div>
                        <button className="btn secondary" onClick={async () => await onCreateOrder()} type="button">Купить
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default observer(FormBuy)
